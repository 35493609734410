import React from "react"

import Layout from "../app/layouts/default"
import Seo from "../app/services/seo"
import { Link } from "../app/atoms/link"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="container">
      <div className="row">
        <div className="col-12 py-5">
          <h1>Pagina non trovata</h1>
          <p>La pagina che cerchi non è disponibile</p>
          <Link to="/">Torna alla homepage</Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
